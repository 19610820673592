import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { userLogout } from "../../../services/user";
import { useSelector } from "react-redux";

function NotYou() {
  const [showModal, setShowModal] = useState(false);
  const { currentUser } = useSelector((state) => state.papalMassBallot);

  return (
    <div>
      <p className="mt-0 mb-0 ">
        Logged in as {currentUser?.fullname}. Not you?
      </p>
      <button
        className="btn btn-link btn-sm mt-0 px-0"
        onClick={() => setShowModal(true)}
      >
        <u>Click here to logout & login to your account</u>
      </button>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={false}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="text-danger font-weight-bold">
            Are you sure?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="my-4 font-weight-bold">
          Continue to logout.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={async () => await userLogout()}>
            Yes, Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NotYou;
