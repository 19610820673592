import React from "react";

function TicketOwner({ user = null }) {
  if (!user) {
    return null;
  }
  return (
    <div
      className="roundcornernocolor py-4 mt-2"
      style={{ border: "2px dashed #c0c0c0" }}
    >
      <div className="text-center">
        <p className="font-weight-bold pb-0 mb-0">{user?.fullname}</p>
        <p className="mb-0">{user?.email}</p>
      </div>
    </div>
  );
}

export default TicketOwner;
