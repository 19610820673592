import React, { useEffect, useContext, useRef, useCallback } from "react";
import fire from "../../../../../services/fire";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useHistory } from "react-router-dom";

import Error from "@material-ui/icons/Error";

import { useForm } from "react-hook-form";

import AppGlobalContext from "../../../../../AppGlobalContext";

import {
  useWardSelection,
  useGuardian,
  useRegistrationDetails,
  useParishesConfig,
} from "../../hooks/hooks";
import {
  registrationCollectionName,
  transfersCollectionName,
} from "../../services/collections";
import { registrationStatusNameToStatus } from "../../services/registrationStatus";

import { sendGenericHTMLEmail } from "../../../../email/emailTemplateHelpers";

async function handleCreateExternalTransfer(
  transferDoc,
  transfersCollection,
  regDoc,
  selectedParish,
  ward,
  uid
) {
  const docData = {
    id: transferDoc.id,
    submittedAt: new Date(),
    submittedBy: uid,
    child: {
      name: ward.name,
      uid: ward.uid,
    },
    registration: regDoc,
    status: "pendingIn",
    from: {
      inSingapore: regDoc.previousParishInSingapore,
      id: regDoc.previousParish,
      programmeId: "",
      timeslotId: "",
      isActive: true,
      hasCatechesis: true,
      isCatchParish: false,
    },
    to: {
      inSingapore: true,
      id: regDoc.selectedParishId || "",
      programmeId: regDoc.programmeType || "",
      timeslotId: regDoc.timeslot || "",
      isActive: true,
      hasCatechesis: true,
      isCatchParish: true,
    },
    outsideBoundaryReason: regDoc.outsideBoundaryReason || "",
    external: "in",
  };

  await transferDoc.set(docData);
}

/**
 * @param {firebase.firestore.CollectionReference<firebase.firestore.DocumentData>} registrationCollection
 * @param {firebase.firestore.CollectionReference<firebase.firestore.DocumentData>} transfersCollection
 */
function handleSaveData(
  registrationCollection,
  transfersCollection,
  registrationDetails,
  registrationData,
  ward,
  uid,
  parishName,
  enquiryEmail,
  setRegistrationStep,
  jumpTo,
  callBack,
  selectedParish
) {
  if (!uid) {
    return () => {};
  }

  const registrationDoc = registrationDetails.id
    ? registrationCollection.doc(registrationDetails.id)
    : registrationCollection.doc();

  const docData = {
    ...registrationDetails,
    ...registrationData,
    submittedAt: new Date(),
    status: registrationStatusNameToStatus("pending"),
  };

  callBack();

  if (registrationDetails.registrationType === "transfer") {
    const transferDoc = transfersCollection.doc();
    docData.transfer = { id: transferDoc.id };
    handleCreateExternalTransfer(
      transferDoc,
      transfersCollection,
      docData,
      selectedParish,
      ward,
      uid
    );
  }

  registrationDoc.update(docData).then(async () => {
    const arrayOfContactEmails = [];

    let selectedContacts = "";

    if (registrationDetails.mainContact) {
      if (registrationDetails.mainContact.name !== "") {
        selectedContacts = selectedContacts.concat(
          registrationDetails.mainContact.name
        );
        arrayOfContactEmails.push(registrationDetails.mainContact.email);
      }
    }

    if (registrationDetails.secondaryContact?.exists) {
      selectedContacts = selectedContacts.concat(
        ` & ${registrationDetails.secondaryContact.name}`
      );
      arrayOfContactEmails.push(registrationDetails.secondaryContact.email);
    }

    if (selectedContacts === "") {
      selectedContacts = "—";
    }

    const programme = selectedParish.programmes.find(
      ({ id }) => id === registrationDetails.programmeType
    );

    const emailHTML = `Dear ${selectedContacts},
			<br/><br/>
      Your registration for ${registrationDetails.child.name} has been submitted to the Programme Coordinator of ${programme.label} in ${parishName} for review. You will receive an email whether it has been successful or not.
      <br/><br/>
      For any clarifications or enquiries, please contact ${enquiryEmail}.
      <br/><br/>
			God Bless,
			<br/>
			myCatholicSG Admin`;

    await sendGenericHTMLEmail({
      email: [...new Set(arrayOfContactEmails)],
      emailSubject: "Catechetical Registration Submitted",
      emailBody: emailHTML,
    });
  });
}

export default function Consent({ registrationStep, setRegistrationStep }) {
  const registrationDetails = useRegistrationDetails();
  const parishesConfig = useParishesConfig();
  const selectedParish = parishesConfig.find(
    ({ id }) => id == registrationDetails.selectedParishId
  );
  const selectedProgramme = selectedParish.programmes.find(
    ({ id }) => registrationDetails.programmeType
  );

  const { user } = useContext(AppGlobalContext);
  const { selectedWardIndex } = useWardSelection();
  const { wardsData } = useGuardian();

  const ward = wardsData[selectedWardIndex];

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);
  const transfersCollection = db.collection(transfersCollectionName);
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      obligation: false,
      consent: false,
    },
  });

  const effectTriggeredRef = useRef(false);

  useEffect(() => {
    if (!effectTriggeredRef.current && registrationDetails.id) {
      effectTriggeredRef.current = true;
      reset({
        obligation: registrationDetails.obligation || false,
        consent: registrationDetails.consent || false,
      });
    }

    return () => {
      effectTriggeredRef.current = false;
      reset();
    };
  }, [registrationDetails]);

  const history = useHistory();
  const callBack = useCallback(
    () => history.push({ pathname: "/catch", registerSuccess: true }),
    [history]
  );

  function onSubmit() {
    const registrationData = getValues();

    let parishName = "—";

    if (selectedParish) {
      parishName = selectedParish.parish;
    }

    let enquiryEmail = "—";

    if (selectedProgramme) {
      enquiryEmail = selectedProgramme.enquiryEmail;
    }

    handleSaveData(
      registrationCollection,
      transfersCollection,
      registrationDetails,
      registrationData,

      ward,
      user.uid,
      parishName,
      enquiryEmail,
      setRegistrationStep,
      registrationStep + 1,
      callBack,
      selectedParish
    );
  }

  return (
    <>
      {!!errors.obligation || !!errors.consent ? (
        <>
          <Card
            className="d-flex flex-column border-danger"
            style={{ backgroundColor: "#FFF4F3" }}
          >
            <Card.Body className="d-flex flex-column align-items-center py-4">
              <Container>
                <h3>There is a problem with your submission</h3>
                {!!errors.obligation ? (
                  <u className="text-danger">
                    Missing response to a required field
                  </u>
                ) : (
                  <></>
                )}
                {!!errors.obligation && !!errors.consent ? <br /> : <></>}
                {!!errors.consent ? (
                  <u className="text-danger">
                    Missing consent to the use of my personal data
                  </u>
                ) : (
                  <></>
                )}
              </Container>
            </Card.Body>
          </Card>
          <br />
        </>
      ) : (
        <></>
      )}
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <h3>e-Consent</h3>
            <br />
            Please read the following statements carefully, then acknowledge
            that you have read and accept terms needed to continue the
            registration.
            <br />
            <br />
            <h6>Parent's responsibilities</h6>
            I commit to sharing my Catholic faith with my child at home and
            bringing my child for Mass every week.
            <br />
            <br />
            <h6>Collection and use of personal information</h6>
            <Row className="w-100 mx-0">
              <Col xs="auto">&bull;</Col>
              <Col className="px-0">
                I agree to the collection, storage, retention, adaptation,
                modification, reading, retrieval, use, transmission, blocking,
                erasure or destruction ("Processing") of the personal data
                provided by me in this form ("Personal Data").
              </Col>
            </Row>
            <Row className="w-100 mx-0 mt-1">
              <Col xs="auto">&bull;</Col>
              <Col className="px-0">
                I agree to the church entity processing the Personal Data for a
                registration to a course by the church entity ("Catechesis").
              </Col>
            </Row>
            <Row className="w-100 mx-0 mt-1">
              <Col xs="auto">&bull;</Col>
              <Col className="px-0">
                I agree to the church capturing photos/videos/audio which may
                contain my/my child's image/audio, and may be used for archival
                & publicity purposes on the church's website & publications.
              </Col>
            </Row>
            <br />
            <h6>Catechist's rights</h6>
            <Row className="w-100 mx-0">
              <Col xs="auto">&bull;</Col>
              <Col className="px-0">
                I agree to the Catechist creating a mobile or web communication
                channel for parents to facilitate communication.
              </Col>
            </Row>
            <Row className="w-100 mx-0 mt-1">
              <Col xs="auto">&bull;</Col>
              <Col className="px-0">
                I agree to the Catechist registering the child for online
                sessions on digital platforms (e.g. Zoom, Google Meet) if
                necessary.
              </Col>
            </Row>
          </Container>
          <br />
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100">
                <Form.Row className="align-items-center">
                  <Row className="w-100 mx-0">
                    <Form.Check
                      className="w-100"
                      type="checkbox"
                      name="obligation"
                      label="I have read, fully understand and accept the obligation to educate my child in the faith and prepare for a eucharistic life"
                      id="obligation"
                      {...register("obligation", { required: true })}
                      isInvalid={!!errors.obligation}
                    />
                  </Row>
                </Form.Row>
                {!!errors.obligation ? (
                  <Row className="align-items-center w-100 my-2 px-2 text-danger">
                    <Error className="mr-1" style={{ fontSize: "18px" }} />
                    Missing a response to a required field
                  </Row>
                ) : (
                  <></>
                )}
                <Form.Row className="align-items-center">
                  <Row className="w-100 mx-0">
                    <Form.Check
                      className="w-100"
                      type="checkbox"
                      name="consent"
                      label="I have read and consent to the processing of my personal data"
                      id="consent"
                      {...register("consent", { required: true })}
                      isInvalid={!!errors.consent}
                    />
                  </Row>
                </Form.Row>
                {!!errors.consent ? (
                  <Row className="align-items-center w-100 my-2 px-2 text-danger">
                    <Error className="mr-1" style={{ fontSize: "18px" }} />
                    Missing consent to the use of my personal data
                  </Row>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Container className="px-0 mt-4">
        <Row>
          <Col className="mt-3 mt-sm-0" xs="auto">
            <Button
              variant="outline-primary"
              size="lg"
              className="w-100"
              onClick={() => {
                setRegistrationStep(registrationStep - 1);

                window.scrollTo(0, 0);
              }}
            >
              Back
            </Button>
          </Col>
          <Col className="d-none d-sm-flex" />
          <Col className="col mt-3 mt-sm-0" sm="auto">
            <Button
              variant="primary"
              size="lg"
              className="w-100"
              onClick={handleSubmit(onSubmit)}
              disabled={!!errors.obligation || !!errors.consent}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Container>
      {registrationDetails.id && registrationDetails.programmeType ? (
        <Container className="px-0 mt-4 text-wrap text-center">
          If you have enquiries, please contact your Parish Catechist
          Coordinator at&nbsp;
          <span className="text-primary" style={{ cursor: "pointer" }}>
            {registrationDetails.programmeType.enquiryEmail}
          </span>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
}
