import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ReloadModal from "../../common/reloadModal";
import { useSelector } from "react-redux";
import {
  updateFirstBallotSeen,
  updateSecondBallotSeen,
} from "../service/ballot";
import SpinnerText from "../../common/spinnerwithText";
import { BALLOT_PHASE_6, BALLOT_PHASE_7 } from "../utils/papalMassConst";
import JourneyWithPope from "./journeyWithPope";

function BallotOutputFail() {
  const history = useHistory();
  const [processing, setProcessing] = useState(false);
  const { ballotDetails, currentUser, phase } = useSelector(
    (state) => state.papalMassBallot
  );

  const firstballotseen = ballotDetails?.firstballotseen || false;
  const firstballotpass = ballotDetails?.firstballotpass || false;
  const secondballotseen = ballotDetails?.secondballotseen || false;
  const secondballotpass = ballotDetails?.secondballotpass || false;

  return (
    <div className="mt-4 row justify-content-center mx-auto">
      <main className="container">
        <ReloadModal />
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow p-0">
            <div className="p-4">
              <div className="text-center">
                <span role="img" aria-label="bird" style={{ fontSize: "80px" }}>
                  🕊️
                </span>
              </div>
              <p className="appearslowly font-weight-bold defaultfontsize text-center mt-4">
                Thank you for participating in the ballot.
              </p>
              <p className="appearslowly font-weight-bold defaultfontsize text-center mb-4">
                Unfortunately, you were not successful in this round.
              </p>
              <p className="mt-1 alert alert-primary">
                {phase === BALLOT_PHASE_6 || phase === BALLOT_PHASE_7 ? (
                  <>
                    Do tune in to watch the livestream on YouTube. God bless!{" "}
                    <span role="img" aria-label="pray">
                      🙏
                    </span>
                  </>
                ) : (
                  <>
                    You will be automatically enrolled in the 2nd round of
                    ballot which begins 5 August, 10:00hr and ends 18 August,
                    23:59hr. Results will be released on 19 August 2024.
                  </>
                )}
              </p>
              <p className="appearslowly mediumfontsize text-center mt-2 mb-3">
                We know that all things work together for good for those who
                love God, who are called according to his purpose. ~ Romans 8:28
              </p>
              <div className="mb-4 appearslowly text-center">
                <div className="">
                  <Button
                    disabled={processing}
                    variant="primary"
                    onClick={async () => {
                      setProcessing(true);
                      if (firstballotpass === true && !firstballotseen) {
                        //update only if fist ballot passed
                        await updateFirstBallotSeen(
                          currentUser?.userid,
                          currentUser?.email,
                          true
                        );
                      }
                      if (secondballotpass === true && !secondballotseen) {
                        //update only if second ballot passed
                        await updateSecondBallotSeen(
                          currentUser?.userid,
                          currentUser?.email,
                          true
                        );
                      }

                      if (
                        phase === BALLOT_PHASE_6 ||
                        phase === BALLOT_PHASE_7
                      ) {
                        history.push("/home");
                      } else {
                        window.location.reload();
                      }
                    }}
                    className="px-5 btn-lg mt-4 biggerfontsize"
                  >
                    {!processing ? (
                      <div className="px-4 font-weight-bold">
                        {phase === BALLOT_PHASE_6 || phase === BALLOT_PHASE_7
                          ? "DONE"
                          : "Proceed"}
                      </div>
                    ) : (
                      <SpinnerText text={"Processing..."} />
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <JourneyWithPope code="rest" hideYoutube={true} />
        </div>
      </main>
    </div>
  );
}

export default BallotOutputFail;
