import fire from "../../../services/fire";
import { getLogActionByID, getLogByActionType } from "../../../services/log";
import { updateUserBallotStatus } from "../../../services/user";
import { BALLOT_USER_INDIVIDUAL_ERROR } from "../utils/papalMassConst";

export async function updateUserBallotState(id, value) {
  try {
    await updateUserBallotStatus(id, value);
    return value;
  } catch (error) {
    return BALLOT_USER_INDIVIDUAL_ERROR;
  }
}

export async function getLastActionByID(id, limit = 1) {
  return await getLogActionByID(id, limit);
}

export async function getLastActionByActionType(
  id,
  actionType = "",
  limit = 1
) {
  return await getLogByActionType(id, actionType, limit);
}

export function getActiveUser() {
  return fire.auth().currentUser;
}

export async function getUserGroupInvites(id) {
  let out = {};
  const func = fire.functions("asia-east2").httpsCallable("getInvitations");
  // console.log("Calling getInvitations()...");
  out = await func({ user: { userid: id } });
  // console.log(out);
  return out;
}

export async function createGroup(id, emails = []) {
  let out = {};
  const func = fire.functions("asia-east2").httpsCallable("createGroup");
  // console.log("Calling createGroup()...");
  out = await func({ user: { userid: id }, emails: emails });
  // console.log(out);
  return out;
}

export async function checkCreateGroup(id, emails = []) {
  let out = {};
  const func = fire.functions("asia-east2").httpsCallable("checkCreateGroup");
  // console.log("Calling checkCreateGroup()...");
  out = await func({ user: { userid: id }, emails: emails });
  // console.log(out);
  return out;
}

export async function joinGroup(actorId, leaderId = "") {
  let out = {};
  const func = fire.functions("asia-east2").httpsCallable("acceptInvite");
  // console.log("Calling acceptInvite()...");
  out = await func({ user: { userid: actorId }, userid: leaderId });
  // console.log(out);
  return out;
}

export async function leaveGroup(actorId, newLeaderId = "") {
  let out = {};
  const func = fire.functions("asia-east2").httpsCallable("leaveGroup");
  // console.log("Calling leaveGroup()...");
  out = await func({ user: { userid: actorId }, userid: newLeaderId });
  // console.log(out);
  return out;
}

export async function addInvite(id, email) {
  let out = {};
  const func = fire.functions("asia-east2").httpsCallable("addInvite");
  // console.log("Calling addInvite()...");
  out = await func({ user: { userid: id }, email: email });
  // console.log(out);
  return out;
}

export async function checkAddInvite(id, email) {
  let out = {};
  const func = fire.functions("asia-east2").httpsCallable("checkAddInvite");
  // console.log("Calling checkAddInvite()...");
  out = await func({ user: { userid: id }, email: email });
  // console.log(out);
  return out;
}

export async function registerUserBallot(user) {
  let out = {};
  const func = fire.functions("asia-east2").httpsCallable("register");
  // console.log("Calling register()...");
  out = await func({ user: user });
  // console.log(out);
  return out;
}

export async function unRegisterUserBallot(user) {
  let out = {};
  const func = fire.functions("asia-east2").httpsCallable("deregister");
  // console.log("Calling deregister()...");
  out = await func({ user: user });
  // console.log(out);
  return out;
}

export async function getLeaderDetails(id) {
  let out = {};
  const func = fire.functions("asia-east2").httpsCallable("getLeaderDetails");
  // console.log("Calling getLeaderDetails()...");
  out = await func({ user: { userid: id } });
  // console.log(out);
  return out;
}

export async function getUserRegistrationDetails(id) {
  let out = {};
  const func = fire
    .functions("asia-east2")
    .httpsCallable("getUserRegistrationDetails");
  // console.log("Calling getUserRegistrationDetails()...");
  out = await func({ user: { userid: id } });
  // console.log(out);
  return out;
}

export async function cancelInvite(id, email) {
  let out = {};
  const func = fire.functions("asia-east2").httpsCallable("cancelInvite");
  // console.log("Calling cancelInvite()...");
  out = await func({ user: { userid: id }, email: email || "" });
  // console.log(out);
  return out;
}

export async function getGroupDetails(id) {
  let out = {};
  const func = fire.functions("asia-east2").httpsCallable("getGroup");
  // console.log("Calling getGroup()...");
  out = await func({ user: { userid: id } });
  // console.log(out);
  return out;
}

export async function declineInvitation(id) {
  let out = {};
  const func = fire.functions("asia-east2").httpsCallable("declineInvitation");
  // console.log("Calling declineInvitation()...");
  out = await func({ user: { userid: id } });
  // console.log(out);
  return out;
}

export async function withdrawBallot(id) {
  let out = {};
  const func = fire.functions("asia-east2").httpsCallable("withdrawBallot");
  // console.log("Calling withdrawBallot()...");
  out = await func({ user: { userid: id } });
  // console.log(out);
  return out;
}

export async function updateFirstBallotSeen(id, email, value) {
  let out = {};
  const func = fire
    .functions("asia-east2")
    .httpsCallable("updateFirstBallotSeen");
  // console.log("Calling updateFirstBallotSeen()...");
  out = await func({
    user: { userid: id, email: email },
    email: email,
    value: value,
  });
  // console.log(out);
  return out;
}

export async function isPFSTicketAvailable(id, email) {
  let out = {};
  const func = fire
    .functions("asia-east2")
    .httpsCallable("isPFSTicketAvailable");
  // console.log("Calling isPFSTicketAvailable()...");
  out = await func({ user: { userid: id, email: email } });
  // console.log(out);
  return out;
}

export async function getPFSTicket(id, email) {
  let out = {};
  const func = fire.functions("asia-east2").httpsCallable("getPFSTicket");
  // console.log("Calling getPFSTicket()...");
  out = await func({ user: { userid: id, email: email } });
  // console.log(out);
  return out;
}

export async function updateSecondBallotSeen(id, email, value) {
  let out = {};
  const func = fire
    .functions("asia-east2")
    .httpsCallable("updateSecondBallotSeen");
  // console.log("Calling updateSecondBallotSeen()...");
  out = await func({
    user: { userid: id, email: email },
    email: email,
    value: value,
  });
  // console.log(out);
  return out;
}
