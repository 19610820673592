import React, { useEffect } from "react";
import Header from "../../common/header";
import PapalMassBallotPlaceholder from "../component/papalMassPlaceholder";
import { getObjectSettingsDB } from "../../../services/objects";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import PageNotFound from "../../common/pageNotFound";
import LoginMiniForm from "../../account/page/loginMini";
import { Modal } from "react-bootstrap";
import {
  BALLOT_PHASE_1,
  BALLOT_PHASE_2,
  BALLOT_PHASE_3,
  BALLOT_PHASE_4,
  BALLOT_PHASE_5,
  BALLOT_PHASE_6,
  BALLOT_PHASE_7,
  BALLOT_ROLE_LEADER,
  BALLOT_ROLE_MEMBER,
  BALLOT_STATUS_REGISTERED,
  BALLOT_STATUS_UNKNOWN,
  BALLOT_STATUS_UNREGISTERED,
  PFS_REG_CANCEL_BALLOT_OK,
  PFS_REG_CREATE_GROUP_BALLOT_OK,
  PFS_REG_INDIVIDUAL_BALLOT,
  PFS_REG_INDIVIDUAL_BALLOT_OK,
  PFS_REG_JOIN_GROUP_BALLOT_OK,
} from "../utils/papalMassConst";
import {
  getFormattedDate,
  getUserBallotObject,
  getUserScreen,
  invitesToList,
  surfaceLeader,
} from "../utils/utils";
import {
  getActiveUser,
  getGroupDetails,
  getLastActionByActionType,
  getUserRegistrationDetails,
} from "../service/ballot";
import { getCurrentUser, getCurrentUserData } from "../../../services/user";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrentUser,
  setLoading,
  setShowPFS,
  setShowLoginModal,
  setPhase,
  setScreen,
  setStatusText,
  setInviteList,
  setInvitationList,
  setAcceptList,
  setRejectList,
  setMemberList,
  setBallotStatus,
  setBallotDetails,
} from "../redux/papalMassBallot";
import GetUserScreen from "../component/getUserScreen";
import pfsLogo from "../../../img/pfs/pfs1.png";
import LazyLoad from "react-lazyload";
import ErrorProcessModal from "../component/errorProcessModal";
import BallotRegistrationClosed from "../component/ballotRegistrationClosed";
import BallotRegistrationResults from "../component/ballotRegistrationResults";
import FinalBallotRegistrationResults from "../component/finalBallotRegistrationResults";
import FirstBallotRegistrationClosed from "../component/firstBallotRegistrationClosed";
import TicketDistributionResults from "../component/ticketDistributionResults";

const Image = ({ src, alt, className, style }) => (
  <LazyLoad once>
    <img src={src} alt={alt} className={className} style={style} />
  </LazyLoad>
);

const PFSLogo = (
  <Image
    src={pfsLogo}
    style={{ maxWidth: "100%", height: "auto" }}
    className="mt-0 mb-0 responsive"
    alt="Papal Mass Logo"
  />
);

function PapalMassBallot() {
  const history = useHistory();
  const user = getActiveUser();
  const dispatch = useDispatch();
  const { loading, showPFS, showLoginModal, phase } = useSelector(
    (state) => state.papalMassBallot
  );

  useEffect(() => {
    async function getData() {
      dispatch(setLoading(true));
      // console.log("papalMassBallot: UseEffect called....");
      const obj = await getObjectSettingsDB();
      const show = obj?.frontbanner?.show || false;
      const phase = obj?.ballotphase || BALLOT_PHASE_1;
      if (user) {
        const uid = user?.uid;
        // console.log(uid);
        const curUserData = await getCurrentUserData(uid);

        //remove certain entries that's not serializable
        const result = await getUserRegistrationDetails(uid);
        // console.log(result);
        if (result?.data?.status === 1) {
          if (result?.data?.user?.role === BALLOT_ROLE_MEMBER) {
            //set member details
            //get ballotdetails if any
            const group = await getGroupDetails(uid);
            // console.log(group);
            dispatch(setMemberList(surfaceLeader(group?.data?.group)));
          } else {
            dispatch(setMemberList([]));
          }
          const userDetails = result?.data?.user;
          const { accepted, rejected, invites, invitations } = userDetails;
          //get accepted list, rejected list, invited list
          // console.log(invites);
          // console.log(invitations);
          if (accepted) {
            // console.log(accepted);
            dispatch(setAcceptList(accepted));
          } else {
            dispatch(setAcceptList([]));
          }
          if (invites?.length > 0) {
            const out = invitesToList(invites);
            // console.log(out);
            dispatch(setInviteList(out));
          } else {
            dispatch(setInviteList([]));
          }
          if (invitations?.length > 0) {
            dispatch(setInvitationList(invitations));
          } else {
            dispatch(setInvitationList([]));
          }
          if (rejected?.length > 0) {
            dispatch(setRejectList(rejected));
          } else {
            dispatch(setRejectList([]));
          }
        }
        //get only the desired object
        const curUser = getUserBallotObject(curUserData);
        //remove certain entries that's not serializable
        dispatch(setCurrentUser({ ...curUser, interests: [] }));
        dispatch(setScreen(getUserScreen(result)));
        dispatch(
          setBallotStatus(
            result?.data?.user?.ballotstatus || BALLOT_STATUS_UNKNOWN
          )
        );
        dispatch(setBallotDetails(result?.data?.user));
        // console.log(result);
        if (result?.data?.user?.ballotstatus === BALLOT_STATUS_REGISTERED) {
          //this is registered
          //check if individual or group
          if (result?.data?.user?.group === true) {
            //leader
            if (result?.data?.user?.role === BALLOT_ROLE_LEADER) {
              const hasBallot = await getLastActionByActionType(
                uid,
                PFS_REG_CREATE_GROUP_BALLOT_OK,
                1
              );
              if (hasBallot?.length > 0) {
                const date = getFormattedDate(new Date(hasBallot[0]?.created));
                dispatch(setStatusText(date));
              }
            } else {
              //member
              // console.log("Member");
              const hasBallot = await getLastActionByActionType(
                uid,
                PFS_REG_JOIN_GROUP_BALLOT_OK,
                1
              );
              if (hasBallot?.length > 0) {
                const date = getFormattedDate(new Date(hasBallot[0]?.created));
                dispatch(setStatusText(date));
              }
            }
          } else {
            //not group
            const hasBallot = await getLastActionByActionType(
              uid,
              PFS_REG_INDIVIDUAL_BALLOT_OK,
              1
            );
            if (hasBallot?.length > 0) {
              const formattedDate = getFormattedDate(
                new Date(hasBallot[0]?.created)
              );
              const text = `${formattedDate}`;
              dispatch(setStatusText(text));
            }
          }
        } else {
          if (result?.data?.user?.ballotstatus === BALLOT_STATUS_UNREGISTERED) {
            //have not registered or previously cancelled
            const hasBallot = await getLastActionByActionType(
              uid,
              PFS_REG_INDIVIDUAL_BALLOT,
              1
            );
            const cancel = await getLastActionByActionType(
              uid,
              PFS_REG_CANCEL_BALLOT_OK,
              1
            );
            const cancelledDate = cancel?.length > 0 ? cancel[0].created : 0;
            const declineDate = result?.data?.user?.declinedinviteat;
            if (declineDate > cancelledDate) {
              //show declined
              const date = getFormattedDate(new Date(declineDate));
              const text = `You declined your invitation on ${date}.`;
              dispatch(setStatusText(text));
            } else {
              //cancelled
              if (hasBallot?.length > 0 && cancel?.length > 0) {
                const date = getFormattedDate(new Date(cancelledDate));
                const text = `You cancelled your ballot on ${date}.`;
                dispatch(setStatusText(text));
              }
            }
          } else {
            //not registered at all
            const text =
              "You have not registered to ballot for the Papal Mass.";
            dispatch(setStatusText(text));
          }
        }
      }
      dispatch(setShowLoginModal(!user));
      dispatch(setPhase(phase));
      dispatch(setShowPFS(show));
      dispatch(setLoading(false));
    }

    getData();
    //added user as dependency to refresh the statusText
  }, [user]);

  if (loading === true) {
    return (
      <LoadingSpinnerNav user={user} smallpic={user?.photoURL} hide="profile" />
    );
  }

  if (!showPFS) {
    //if banner is not shown
    return <PageNotFound user={user} />;
  }

  const RenderMainContainer = () => {
    /*
    Phases:
    1 - papal mass ballot placeholder
    2 - registration for ballot
    3 - close registration for ballot AND start of ballot
    4 - 1st ballot results
    5 - 2nd ballot registration?
    6 - 2nd ballot results
    7 - ticket assigning
    8 ???
    */

    switch (phase) {
      case BALLOT_PHASE_1: //papal mass ballot placeholder
        return <PapalMassBallotPlaceholder user={user} />;
      case BALLOT_PHASE_2: //registration for ballot
        return <GetUserScreen img={PFSLogo} />;
      case BALLOT_PHASE_3: //close registration for ballot AND start of 1ST ballot
        return <BallotRegistrationClosed img={PFSLogo} />;
      case BALLOT_PHASE_4: //ballot registration RESULTS
        return <BallotRegistrationResults img={PFSLogo} />;
      case BALLOT_PHASE_5: //close registration for ballot AND start of 2ND ballot
        return <FirstBallotRegistrationClosed img={PFSLogo} />;
      case BALLOT_PHASE_6: //2nd ballot results
        return <FinalBallotRegistrationResults img={PFSLogo} />;
      case BALLOT_PHASE_7: //ticket distribution
        return <TicketDistributionResults img={PFSLogo} />;
      default:
        return <PapalMassBallotPlaceholder user={user} />;
    }
  };

  return (
    <div>
      <Header user={user} smallpic={user?.photoURL} hide="profile" />
      <ErrorProcessModal />
      <RenderMainContainer />
      <Modal
        show={showLoginModal}
        animation={false}
        centered
        backdrop="static"
        keyboard={false}
      >
        <div className="text-left mx-4">
          <LoginMiniForm
            source="papalmassballot"
            successCallback={async () => {
              const newUser = await getCurrentUser();
              const data = await getCurrentUserData(newUser.uid);
              const { catholic, agreeterms, agreemcsg, hasresetpass } = data;
              //check if user is catholic or non catholic, then redirect to appropriate page
              let askcatholic = true;
              if (catholic === false || catholic === true) {
                askcatholic = false;
              }
              let askconsent = true;
              if (agreeterms === true && agreemcsg === true) {
                //this user has already set consent, users will not be able to use this system if all are false or undefined
                askconsent = false;
              }
              let forceresetpass = true;
              if (hasresetpass === true) {
                forceresetpass = false;
              }
              if (
                askcatholic === true ||
                askconsent === true ||
                forceresetpass === true
              ) {
                history.push("/home");
              } else {
                dispatch(setShowLoginModal(false));
              }
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

export default PapalMassBallot;
