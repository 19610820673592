import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  BALLOT_PHASE_3,
  BALLOT_STATUS_BALLOTED_SUCCESS,
  BALLOT_STATUS_BALLOTED_UNSUCCESSFUL,
  BALLOT_STATUS_REGISTERED,
  NEXT_BALLOT_END,
  NEXT_BALLOT_START,
} from "../utils/papalMassConst";
import ReloadModal from "../../common/reloadModal";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import { useSelector } from "react-redux";
import {
  extractTimestamp,
  getFormattedDate,
  surfaceLeader,
} from "../utils/utils";
import { getGroupDetails } from "../service/ballot";
import DisplayMembers from "./displayMembers";
import JourneyWithPope from "./journeyWithPope";

function ClosedRegistration() {
  const [memberList, setMemberList] = useState([]);
  const { currentUser, ballotStatus, ballotDetails, phase } = useSelector(
    (state) => state.papalMassBallot
  );
  const history = useHistory();
  // console.log(ballotDetails);

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status

    async function getData() {
      if (ballotDetails?.group) {
        const group = await getGroupDetails(currentUser?.userid);
        if (isMounted && group?.data?.group) {
          setMemberList(surfaceLeader(group?.data?.group));
        }
      }
    }

    getData();
    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="mt-4 row justify-content-center mx-auto">
      <main className="container">
        <ReloadModal />
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow p-4">
            {ballotStatus === BALLOT_STATUS_REGISTERED ||
            ballotStatus === BALLOT_STATUS_BALLOTED_SUCCESS ||
            ballotStatus === BALLOT_STATUS_BALLOTED_UNSUCCESSFUL ? (
              <>
                <div className="text-center">
                  <span
                    role="img"
                    aria-label="pray"
                    style={{ fontSize: "80px" }}
                  >
                    🙏
                  </span>
                </div>
                <p className="appearslowly font-weight-bold defaultfontsize text-center mt-3">
                  Thank you for participating in the ballot for the Papal Mass.
                </p>

                {!ballotDetails?.group ? (
                  <div className="mt-1 py-3 alert alert-primary text-center">
                    <div className="font-weight-bold defaultfontsize">
                      Individual Ballot
                    </div>
                    {ballotDetails?.hasOwnProperty("history") && (
                      <div className="mt-0 mediumfontsize">
                        Registered on{" "}
                        {getFormattedDate(
                          new Date(
                            extractTimestamp(
                              ballotDetails?.history[
                                ballotDetails?.history?.length - 1
                              ]?.timestamp
                            )
                          )
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="pb-3">
                    <DisplayMembers memberList={memberList} />
                  </div>
                )}

                <p className="appearslowly normalsmallfontsize text-center mt-2 mb-3">
                  {phase === BALLOT_PHASE_3
                    ? "The outcome will be released here on 5th August, 10am."
                    : "The outcome will be released here on 19th August, 10am."}
                </p>
                <p className="appearslowly normalsmallfontsize text-center mt-2">
                  Stay tuned!
                </p>
              </>
            ) : (
              <>
                <div className="text-center">
                  <ErrorRoundedIcon
                    className="text-warning appearslowly bounce"
                    style={{ fontSize: "100px" }}
                  />
                </div>
                <p className="appearslowly font-weight-bold defaultfontsize text-center mt-4 mb-4">
                  Ballot registration is closed
                </p>
                <div className="mt-1 alert alert-primary">
                  {phase === BALLOT_PHASE_3 ? (
                    <>
                      <p>
                        <span role="img" aria-label="celebrate">
                          🎉
                        </span>{" "}
                        Not to worry, there will be a 2nd ballot!
                      </p>
                      <p>
                        Registration will open again from{" "}
                        <b>{NEXT_BALLOT_START}</b> - <b>{NEXT_BALLOT_END}</b>.
                      </p>
                    </>
                  ) : (
                    <>
                      {/* Phase 4, 2nd ballot closing */}
                      <p>
                        Do tune in to watch the livestream on YouTube. God
                        bless!{" "}
                        <span role="img" aria-label="pray">
                          🙏
                        </span>
                      </p>
                    </>
                  )}
                </div>
                <p className="appearslowly normalsmallfontsize text-center mt-0 mb-3">
                  Stay tuned!
                </p>
              </>
            )}

            <div className="appearslowly text-center">
              <div className="">
                <Button
                  variant="primary"
                  onClick={() => history.push("/home")}
                  className="px-5 btn-lg mt-4 biggerfontsize"
                >
                  Done
                </Button>
              </div>
            </div>
          </div>
          <JourneyWithPope code="rest" hideYoutube={true} />
        </div>
      </main>
    </div>
  );
}

export default ClosedRegistration;
