import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import Input from "../../common/input";
import SpinnerText from "../../common/spinnerwithText";
import {
  declineInvitation,
  isPFSTicketAvailable,
  updateFirstBallotSeen,
} from "../service/ballot";
import { useDispatch, useSelector } from "react-redux";
import { setErrorProcessing } from "../redux/papalMassBallot";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ReloadModal from "../../common/reloadModal";
import { BALLOT_PHASE_4, BALLOT_PHASE_7 } from "../utils/papalMassConst";
import JourneyWithPope from "./journeyWithPope";
import ConfettiExplosion from "react-confetti-explosion";
import TicketResults from "./ticketResults";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import TicketOwner from "./ticketOwner";
import NotYou from "./notYou";

const largeProps = {
  force: 0.8,
  duration: 3000,
  particleCount: 200,
  width: 1600,
  // colors: ["#041E43", "#1471BF", "#5BB4DC", "#FC027B", "#66D805"],
};

function SpecialInvite({ img, showDecline = true }) {
  const [showModal, setShowModal] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [processing, setProcessing] = useState(false);
  const [isTixAvailable, setIsTixAvailable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [done, setDone] = useState(false);
  const { currentUser, ballotDetails, phase } = useSelector(
    (state) => state.papalMassBallot
  );
  const ticketType = ballotDetails?.type; //MCS, SIS, OTHER???

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status

    async function getData() {
      if (phase === BALLOT_PHASE_7) {
        const isTicketAvailable = await isPFSTicketAvailable(
          currentUser?.userid,
          currentUser?.email
        );
        if (isMounted && isTicketAvailable?.data?.available) {
          setIsTixAvailable(true);
        }
        if (isMounted && isTicketAvailable?.data?.status === 0) {
          setIsTixAvailable(false);
        }
      } //end of BALLOT_PHASE_7
      if (isMounted) {
        setLoading(false);
      }
    }
    getData();
    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted = false;
    };
  });

  const handleChange = ({ currentTarget: input }) => {
    setConfirmText(input.value);
  };

  const doDecline = async () => {
    setProcessing(true);
    //decline
    const uid = currentUser?.userid;
    const result = await declineInvitation(uid);
    if (result?.data?.status === 0 || result?.data?.status === -1) {
      dispatch(setErrorProcessing(true));
      return;
    } else {
      //success
      if (phase === BALLOT_PHASE_4) {
        await updateFirstBallotSeen(
          currentUser?.userid,
          currentUser?.email,
          true
        );
      }
      setDone(true);
    }
    setProcessing(false);
  };

  if (loading === true) {
    return (
      <LoadingSpinnerNav
        user={currentUser}
        smallpic={currentUser?.photoURL}
        hide="profile"
      />
    );
  }

  return (
    <div className="mt-4 row justify-content-center mx-auto">
      <ReloadModal />
      <main className="container">
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow px-4 pt-3 pb-4">
            <div className="d-flex justify-content-center align-items-center">
              <ConfettiExplosion zIndex={10000} {...largeProps} />
            </div>
            {img}
            {/* <div className="mt-2 pb-0 mb-0 bg-white">
              <hr style={{ borderTop: "2px dashed #f0f0ef" }} />
            </div> */}
            <div className="mt-4 mx-auto justify-content-center d-flex text-center align-items-center">
              <DraftsOutlinedIcon
                className={` appearslowly text-success`}
                style={{ fontSize: "60px" }}
              />
            </div>

            <p className="appearslowly font-weight-bold defaultfontsize text-center mt-3">
              You have been invited to attend the Papal Mass!
            </p>
            <TicketOwner user={currentUser} />
            <div className="mt-4 mb-3 text-center">
              <NotYou />
            </div>
            {!isTixAvailable && ticketType === "MCS" ? (
              <p className="px-4 mt-1 alert alert-primary">
                {/* Your ticket will be available here in September. */}
                Your ticket is being processed and will be made available here.
                If you do not receive your ticket by 11 September, please reach
                out to the Digital Church Office at mycatholicsg@catholic.org.sg
              </p>
            ) : (
              <TicketResults type={ticketType} text="invitation" />
            )}

            <p className="appearslowly normalsmallfontsize text-center my-2">
              We look forward to seeing you at the Papal Mass.
            </p>
            <p className="appearslowly normalsmallfontsize text-center my-3">
              God bless you{" "}
              <span role="img" aria-label="pray">
                🙏
              </span>
            </p>

            <div className="appearslowly text-center">
              <div className="">
                <Button
                  variant={`${
                    ticketType === "MCS" && isTixAvailable
                      ? "outline-primary"
                      : "primary"
                  }`}
                  onClick={() => history.push("/home")}
                  className="btn-lg my-4 biggerfontsize px-5"
                >
                  <div className="font-weight-bold mx-4">Done</div>
                </Button>
              </div>
              {showDecline && (
                <div className="">
                  <Button
                    variant="link"
                    onClick={() => setShowModal(true)}
                    className="btn-lg mt-0 biggerfontsize text-danger"
                  >
                    Decline Invitation
                  </Button>
                </div>
              )}
            </div>
          </div>
          <JourneyWithPope code="inv" hideYoutube={true} />
        </div>
      </main>
      <Modal
        show={showModal}
        animation={false}
        centered
        backdrop="static"
        keyboard={false}
      >
        {!done && (
          <div className="text-center align-items-center d-flex justify-content-center flex-column m-4">
            <p className="font-weight-bold appearslowly text-dark defaultfontsize text-left mt-2 mb-3">
              Are you sure you want to decline the invitation?
            </p>
            <p className="appearslowly text-dark defaultfontsize text-left mt-2 mb-3">
              You will lose your invited spot for the upcoming Papal Mass. You
              will still be able to participate in the ballot but will not be
              guaranteed a spot.
            </p>
            <p className="appearslowly text-dark defaultfontsize text-left mt-2 mb-3">
              Type <b>DECLINE</b> below if you are absolutely sure you wish to
              proceed{" "}
            </p>
            <Input
              disabled={processing}
              autoFocus={true}
              css="pl-0 pb-0 mt-2 mb-3 text-center"
              type="text"
              name={`email`}
              value={confirmText}
              label=""
              placeholder=""
              onChange={handleChange}
            />
            <Button
              disabled={processing || confirmText !== "DECLINE"}
              variant={"danger"}
              className="btn-lg mt-3 px-4"
              onClick={doDecline}
            >
              {!processing ? (
                <div className=" font-weight-bold">Decline Invitation</div>
              ) : (
                <SpinnerText text={"Processing..."} />
              )}
            </Button>
            <Button
              disabled={processing}
              variant={"link"}
              className="btn-lg mt-3 biggerfontsize"
              onClick={() => setShowModal(false)}
            >
              Go Back
            </Button>
          </div>
        )}
        {done && (
          <div className="text-center align-items-center d-flex justify-content-center flex-column m-4">
            <div className="appearslowly text-center">
              <CheckCircleOutlineIcon
                style={{ fontSize: "100px", color: "green" }}
              />
              <h3 className="my-4">
                You have successfully declined the invitation!
              </h3>
              <div className="">
                <Button
                  variant="primary"
                  onClick={() => history.push("/home")}
                  className="btn-lg my-4 biggerfontsize px-5"
                >
                  <div className="mx-4">Done</div>
                </Button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default memo(SpecialInvite);
