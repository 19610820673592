import React from "react";
import { BALLOT_ROLE_LEADER } from "../utils/papalMassConst";

function DisplayMembers({ memberList }) {
  return (
    <div>
      {memberList?.length > 0 && (
        <p className="appearslowly font-weight-bold text-dark text-left mt-2 mb-2">
          Member{memberList?.length > 1 ? "s" : ""} (
          {parseInt(memberList?.length)})
        </p>
      )}
      {memberList.map((i) => {
        //get the name
        // console.log(i);
        const email = i?.email; //`${BALLOT_EMAIL_PREFIX}${i?.id}`;
        const name = i?.name || "";
        return (
          <div
            key={email}
            style={{ border: "1px dashed #a0a0a0" }}
            className="roundsmallcornernocolor lightframecolor btn-block my-1 py-2 px-2 text-left"
          >
            <div className="">
              <span className="text-primary font-weight-bolds">{name}</span>
              {i?.role === BALLOT_ROLE_LEADER && (
                <div className="ml-1 badge badge-pill badge-danger defaultsmallfontsize">
                  Leader
                </div>
              )}
            </div>
            {/* <div className="text-primary biggerfontsize">{i[email]}</div> */}
          </div>
        );
      })}
    </div>
  );
}

export default DisplayMembers;
