import React, { useState } from "react";
import { Media, Modal } from "react-bootstrap";
import SpinnerText from "../../common/spinnerwithText";
import { getPFSTicket } from "../service/ballot";
import { useSelector } from "react-redux";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import { capitalizeFirstLetterNoSpaces } from "../../../utils/string";
import { convertTSFilename } from "../../../utils/date";

function TicketResults({ type = "", text = "" }) {
  const [showModal, setShowModal] = useState(false);
  const [downloadedFilename, setDownloadedFilename] = useState("");
  const [agree, setAgree] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const { currentUser } = useSelector((state) => state.papalMassBallot);
  // Helper function to convert base64 to Blob
  const base64ToUint8Array = (base64) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
  };

  const NoticeElement = ({ content }) => {
    return (
      <Media className="mb-3 d-flex align-items-start justify-content-start">
        <div>
          <CheckCircleRoundedIcon
            className="text-success"
            style={{ fontSize: "24px" }}
          />
        </div>
        <Media.Body className="ml-1">{content}</Media.Body>
      </Media>
    );
  };

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const { userid, email } = currentUser;
      const ticketUrl = await getPFSTicket(userid, email);
      const downloadedPdf = ticketUrl?.data?.file;
      // Convert base64 to Uint8Array
      const byteCharacters = base64ToUint8Array(downloadedPdf);
      const blob = new Blob([byteCharacters], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      const now = convertTSFilename(new Date().getTime());
      a.href = url;
      const filename = `${capitalizeFirstLetterNoSpaces(
        currentUser?.fullname
      )}-${now}.pdf`; // Set the desired file name
      setDownloadedFilename(filename);
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url); // Clean up the URL object
      setDownloadComplete(true);
    } catch (e) {
      console.log(e);
    }
    setDownloading(false);
  };

  if (type === "MCS") {
    return (
      <div className="appearslowly text-center mt-3 mx-0 mb-4">
        <button
          onClick={() => setShowModal(true)}
          className="font-weight-bold px-5 btn-block btn btn-success btn-lg"
        >
          Download Ticket
        </button>
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          animation={false}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body className="mx-3 mt-4">
            {!downloadComplete ? (
              <div className="">
                <div className="alert alert-primary text-center mb-4">
                  <div className="biggerfontsize font-weight-bold">
                    {currentUser?.fullname}
                  </div>
                  <div className="biggerfontsize font-weight-bold">
                    {currentUser?.email}
                  </div>
                </div>
                <NoticeElement
                  content={<>Your Papal Mass ticket is non-transferable.</>}
                />
                <NoticeElement
                  content={
                    <>
                      Each pdf contains 1 ticket only. If you are in a group,
                      kindly inform your members to log in to their accounts and
                      retrieve their ticket.
                    </>
                  }
                />
                <NoticeElement
                  content={
                    <>
                      Please bring your photo ID for verification on the day of
                      the event.
                    </>
                  }
                />
                <NoticeElement
                  content={
                    <>
                      For ticketing enquiries, you may reach out to the Digital
                      Church Office at{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`mailto:mycatholicsg@catholic.org.sg`}
                      >
                        mycatholicsg@catholic.org.sg
                      </a>
                    </>
                  }
                />
                <div className="mt-3 form-group col-sm-12 text-center px-2">
                  <div
                    className="mx-0 text-left custom-control-lg custom-control custom-checkbox"
                    style={{ zIndex: "0" }}
                  >
                    <input
                      disabled={downloading}
                      className="custom-control-input"
                      value={agree}
                      id="agree"
                      name="agree"
                      type="checkbox"
                      onChange={(e) => setAgree(!agree)}
                      defaultChecked={agree}
                    />
                    <label className="custom-control-label" htmlFor="agree">
                      <span className="pointer font-weight-bold">
                        I understand.
                      </span>
                    </label>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    disabled={downloading || !agree}
                    className="mt-4 btn btn-success btn-block btn-lg"
                    onClick={handleDownload}
                  >
                    {!downloading ? (
                      <div className="font-weight-bold px-3">
                        Download Ticket
                      </div>
                    ) : (
                      <div className="px-4">
                        <SpinnerText text={"Downloading..."} />
                      </div>
                    )}
                  </button>
                </div>
                <div className="text-center">
                  <button
                    disabled={downloading}
                    className="mt-4 btn btn-link btn-lg"
                    onClick={() => setShowModal(false)}
                  >
                    <div className="mx-4">Close</div>
                  </button>
                </div>
              </div>
            ) : (
              <div className="text-center">
                <CheckCircleRoundedIcon
                  className={`text-success appearslowly`}
                  style={{ fontSize: "90px" }}
                />
                <p className="pt-3 font-weight-bold defaultfontsize">
                  Your ticket has been downloaded and saved on your device.
                </p>
                <p className="pt-2 pb-0 mb-0 normalsmallfontsize">
                  <i>{downloadedFilename}</i>
                </p>
                <p className="text-muted pt-0 normalsmallfontsize">Filename</p>
                <div className="text-center">
                  <button
                    disabled={downloading}
                    className="mt-3 btn btn-link btn-lg"
                    onClick={() => {
                      setDownloadComplete(false);
                      setShowModal(false);
                    }}
                  >
                    <div className="mx-4">Close</div>
                  </button>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  } else if (type === "SIS") {
    return (
      <div className="alert alert-primary">
        <p>
          Your ticket will be sent directly to your email address via SISTIC.
        </p>
        <p>
          You should ONLY receive your ticket from{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:feedback@sistic.com.sg`}
            className="font-weight-bold"
          >
            feedback@sistic.com.sg
          </a>
        </p>
        <p>
          Ps: Don’t forget to check your spam / junk folder
          <span role="img" aria-label="spam">
            ✨😉
          </span>
        </p>
        <p>
          Alternatively, you may reach out to your {text} coordinator for
          further enquiries.
        </p>
      </div>
    );
  } else {
    if (text === "") {
      return (
        <div className="alert alert-primary">
          Your ticket is being processed and will be made available here. If you
          do not receive your ticket by 7 September, please reach out to the
          Digital Church Office at mycatholicsg@catholic.org.sg
        </div>
      );
    }
    return (
      <div className="alert alert-primary">
        Please contact your {text} coordinator to receive your Papal Mass
        Ticket.
      </div>
    );
  }
}

export default TicketResults;
