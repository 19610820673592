import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import ReloadModal from "../../common/reloadModal";
import JourneyWithPope from "./journeyWithPope";
import ConfettiExplosion from "react-confetti-explosion";
import { useSelector } from "react-redux";
import { BALLOT_PHASE_7 } from "../utils/papalMassConst";
import { isPFSTicketAvailable } from "../service/ballot";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import TicketResults from "./ticketResults";
import TicketOwner from "./ticketOwner";
import NotYou from "./notYou";

const largeProps = {
  force: 0.8,
  duration: 3000,
  particleCount: 200,
  width: 1600,
  // colors: ["#041E43", "#1471BF", "#5BB4DC", "#FC027B", "#66D805"],
};

function VolunteerScreen({ img }) {
  const [isTixAvailable, setIsTixAvailable] = useState(false);
  const [loading, setLoading] = useState(true);
  const { currentUser, ballotDetails, phase } = useSelector(
    (state) => state.papalMassBallot
  );
  const ticketType = ballotDetails?.type; //MCS, SIS, OTHER???

  const history = useHistory();

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status

    async function getData() {
      if (phase === BALLOT_PHASE_7) {
        const isTicketAvailable = await isPFSTicketAvailable(
          currentUser?.userid,
          currentUser?.email
        );
        if (isMounted && isTicketAvailable?.data?.available) {
          setIsTixAvailable(true);
        }
        if (isMounted && isTicketAvailable?.data?.status === 0) {
          setIsTixAvailable(false);
        }
      } //end of BALLOT_PHASE_7
      if (isMounted) {
        setLoading(false);
      }
    }
    getData();
    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted = false;
    };
  });

  if (loading === true) {
    return (
      <LoadingSpinnerNav
        user={currentUser}
        smallpic={currentUser?.photoURL}
        hide="profile"
      />
    );
  }

  return (
    <div className="mt-4 row justify-content-center mx-auto">
      <main className="container">
        <ReloadModal />
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow px-4 pt-3 pb-4">
            <div className="d-flex justify-content-center align-items-center">
              <ConfettiExplosion zIndex={10000} {...largeProps} />
            </div>
            {img}
            {/* <div className="mt-3 pb-0 mb-1 bg-white">
              <hr style={{ borderTop: "2px dashed #f0f0ef" }} />
            </div> */}
            <div className="mt-2 mx-auto justify-content-center d-flex text-center align-items-center">
              <span role="img" aria-label="clap" style={{ fontSize: "80px" }}>
                👏
              </span>
            </div>
            <p className="appearslowly font-weight-bold defaultfontsize text-center mt-2 mb-3">
              You have signed up as a volunteer for the Papal Mass!
            </p>
            <TicketOwner user={currentUser} />
            <div className="mt-4 mb-3 text-center">
              <NotYou />
            </div>
            {!isTixAvailable && ticketType === "MCS" ? (
              <p className="px-4 mt-1 alert alert-primary">
                {/* Your ticket will be available here in September. */}
                Your ticket is being processed and will be made available here.
                If you do not receive your ticket by 11 September, please reach
                out to the Digital Church Office at mycatholicsg@catholic.org.sg
              </p>
            ) : (
              <TicketResults type={ticketType} text="volunteer" />
            )}
            <p className="appearslowly normalsmallfontsize text-center my-2">
              We look forward to seeing you at the Papal Mass.
            </p>
            <p className="appearslowly normalsmallfontsize text-center my-3">
              God bless you{" "}
              <span role="img" aria-label="pray">
                🙏
              </span>
            </p>

            <div className="appearslowly text-center">
              <div className="">
                <Button
                  variant="link"
                  onClick={() => history.push("/home")}
                  className="btn-lg mt-4 biggerfontsize"
                >
                  Go Back
                </Button>
              </div>
            </div>
          </div>
          <JourneyWithPope code="vol" hideYoutube={true} />
        </div>
      </main>
    </div>
  );
}

export default memo(VolunteerScreen);
