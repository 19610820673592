/**
 * Camelize
 *
 * Input: the quick brown fox
 *
 * Ouput: theQuickBrownFox
 *
 */
export function camelize(str) {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export function removePPFromPassport(input) {
  let result = input;
  if (input.substring(0, 3) === "PP-") {
    result = input.substring(3, input.length);
  }
  return result;
}
/**
 * capitalizeFirstLetter
 *
 * Input: the quick brown fox
 *
 * Ouput: The Quick Brown Fox
 *
 */
export function capitalizeFirstLetter(string) {
  return string.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
}

export function capitalizeFirstLetterNoSpaces(string) {
  return capitalizeFirstLetter(string.replace(/\s/g, ""));
}

/**
 * Convert String to integers
 *
 * Input: "PFS"
 *
 * Output: "807083"
 *
 */
export function convertToInteger(input = "") {
  if (input?.length === 0) return "";
  return parseInt(
    Array.from(input)
      .map((char) => char.charCodeAt(0))
      .join("")
  );
}
